var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "fill-height": "", "text-center": "" } },
    [
      _c("div", { staticClass: "d-flex justify-center flex-column w-100" }, [
        _c("p", { staticClass: "mb-5" }, [
          _vm._v(
            "We are sorry, but it seems the page you requested does not currently exist."
          ),
        ]),
        _c(
          "div",
          [
            _c(
              "v-btn",
              { attrs: { to: { name: "welcome" }, color: "primary" } },
              [_vm._v("Go to Dashboard")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }